const nav = [
    {
        label: 'Licenças',
        icon: 'pi-calendar',
        key: '0',
        items: [
            {
                label: 'Pesquisar',
                icon: 'pi-minus',
                to: "/licenses",
                key: 'licenses',
                parent: '0'
            },
            {
                label: 'Licenças Pendentes',
                icon: 'pi-minus',
                to: "/pending_licenses",
                key: 'pending_licenses',
                parent: '0'
            },
            {
                label: 'Licenças Expiradas',
                icon: 'pi-minus',
                to: "/expired_licenses",
                key: 'expired_licenses',
                parent: '0'
            },
            // {
            //     label: 'Licenças Inativas',
            //     icon: 'pi-minus',
            //     to: "/inactive_licenses",
            //     key: 'inactive_licenses',
            //     parent: '0'
            // },
            // {
            //     label: 'Criar Nova Licença',
            //     icon: 'pi-minus',
            //     to: "/new_license",
            //     key: 'new_license',
            //     parent: '0'
            // },
            // {
            //     label: 'Criar Nova Loja',
            //     icon: 'pi-minus',
            //     to: "/new_store/SGRMUPEFZA",
            //     key: 'new_store',
            //     parent: '0'
            // },
            // {
            //     label: '123Lista de Lojas [temp] ',
            //     icon: 'pi-minus',
            //     to: "/store_list/SGRMUPEFZA",
            //     key: 'store_list',
            //     parent: '0'
            // }
        ]

    }
]

if (import.meta.env.VITE_PLAYGROUND === 'true') {
    nav[0].items.push(
        {
            label: 'Playground',
            icon: 'pi-minus',
            to: "/playground",
            key: 'playground',
            parent: '0'
        },        
    )
}

export default nav;