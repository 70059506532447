// import './axios.interceptor.js';
import axios from "axios";
import api_url from "./api_url";

export default class UserService {
  constructor(base_url) {
    if (base_url) {
      this.base_url = base_url;
    } else {
      this.base_url = api_url;
    }
  }

  async getClient() {
    const response = await fetch("./clients.json");
    const file = await response.json();
    return file;
  }

  async login(loginData) {
    const response = await axios.post(
      `${this.base_url}/v1/auth/login`,
      loginData
    );
    return response;
  }

  async changePassword(loginData) {
    const response = await axios.post(
      `${this.base_url}/v1/auth/change-password`,
      loginData
    );
    return response;
  }

  async getlicenseList(token) {
    const response = await axios.get(`${this.base_url}/v1/license`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async getPartner(token) {
    const response = await axios.get(
      `${this.base_url}/v1/auth/get-partner-data`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  async savePartner(token, partner) {
    const response = await axios.post(
      `${this.base_url}/v1/auth/save-partner-data`,
      partner,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  async getServerVersion() {
    const response = await axios.get(`${this.base_url}/version`);
    return response.data;
  }

  async ping() {
    const response = await axios.get(`${this.base_url}/ping`);
    return response.data;
  }
}
