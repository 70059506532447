import { defineStore } from 'pinia'

//import ref from vue
import { ref, computed } from 'vue'


//define a pinia store called 'app'
export const useAppStore = defineStore('app', () => {
    //define a state

    const version = ref('0')


    const setVersion = (v) => {
        version.value = v
    }

    //return the state, getters and actions
    return {
        version,
        setVersion
    }
}
)