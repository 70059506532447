<template>
  <div class="flex gap-2 flex-column">
    <label v-if="showLabel">{{ label || "&nbsp;" }}</label>
    <InputText
      v-if="props.type == 'text'"
      :readonly="$attrs.readonly"
      :disabled="$attrs.disabled"
      :class="{ 'p-invalid': error }"
      v-model="textValue"
      v-on:blur="onB"
    />
    <Textarea
      v-if="props.type == 'textarea'"
      :rows="$attrs.rows"
      v-model="textValue"
      v-on:blur="onB"
    ></Textarea>
    <small v-if="err || validationRule" style="color: red">{{
      err || validationRule
    }}</small>
    <small v-if="!(err || validationRule) && helpText" style="color: #999">{{
      props.helpText
    }}</small>
  </div>
</template>
<script>
// use normal <script>  to declare options
export default {
  // inheritAttrs: false,
};
</script>
<script setup>
import { ref, watch } from "vue";

import { useValidator } from "@/use/validator";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const validationRule = ref("");

const { validate } = useValidator(t);

const emit = defineEmits(["update:modelValue", "error", "bb"]);

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object, Boolean],
    default: "",
  },
  rules: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  helpText: {
    type: String,
    default: "",
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: "text",
  },
});

const textValue = ref(props.modelValue ?? "");

const err = ref(props.error);

watch(
  () => props.error,
  (val) => {
    err.value = val;
  }
);

watch(
  () => props.modelValue,
  (val) => {
    textValue.value = val;
    doValidation();
  }
);

watch(
  () => props.rules,
  () => {
    doValidation();
  }
);

watch(
  () => textValue.value,
  (val) => {
    emit("update:modelValue", val);
  }
);

const doValidation = () => {
  if (!props.rules) {
    emit("error", "");
    err.value = "";
    validationRule.value = "";
    return "";
  }
  const error = validate(textValue.value, props.rules);
  emit("error", error);
  validationRule.value = error;
};

doValidation();

const onB = () => {
  emit("bb", textValue.value);
};
</script>

<style lang="scss" scoped>
label {
  color: rgb(177, 177, 177);
}
</style>
