import { defineStore } from "pinia";

import { ref, computed } from "vue";

import UserService from "@/services/client";

import { useAppStore } from "./app";

import storage from "@/services/storage";

export const useUserStore = defineStore("user", () => {
  const storedUser = storage.getJSONItem("user", {});
  const storedToken =
    (import.meta.env?.VITE_PERSIST_SESSION ?? "false") === "true"
      ? storage.getItem("token", "")
      : "";
  // const storedPartner = storage.getJSONItem('partner', {})
  const appStore = useAppStore();

  const user = ref(storedUser);
  const partner = ref({});
  const origin_partner = ref({});

  const token = ref(storedToken);

  const mustLogin = ref(false);
  const maintenance = ref(false);

  const us = new UserService();

  const isLogged = computed(() => token.value !== "" || mustLogin.value);

  async function login(loginData) {
    const response = await us.login(loginData);
    token.value = response.data.data.access_token;
    user.value = response.data.data.user;
    storage.setItem("token", response.data.data.access_token);
    storage.setJSONItem("user", response.data.data.user);
    appStore.setVersion(response.data.data.version);
  }

  async function changePassword(loginData) {
    const data = await us.changePassword(loginData);
    return true;
  }
  //ZSLW-66
  async function getPartner() {
    const data = await us.getPartner(token.value);
    origin_partner.value = data?.data?.[0] ?? {};
    partner.value = data?.data?.[0] ?? {};
  }
  //ZSLW-66
  async function savePartner() {
    const response = await us.savePartner(token.value, partner.value);
    return response;
  }

  async function logout(loginData) {
    reset();
  }

  async function reset() {
    token.value = "";
    user.value = {};
    storage.removeItem("token");
    storage.removeItem("user");
    storage.removeItem("partner");
  }

  async function setMustLogin(reload) {
    mustLogin.value = true;
    reset();
    // if (reload) window.location.reload()
  }

  function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  async function setMaintenance(reload) {
    maintenance.value = true;
    reset();
    if (!endsWith(window.location.href, "/maintenance"))
      window.location = "/maintenance";
  }

  async function getServerVersion() {
    const data = await us.getServerVersion();
    return data;
  }

  async function ping() {
    const data = await us.ping();
    return data;
  }

  return {
    user,
    token,
    isLogged,
    login,
    logout,
    mustLogin,
    setMustLogin,
    changePassword,
    getPartner,
    partner,
    savePartner,
    getServerVersion,
    setMaintenance,
    maintenance,
    ping,
  };
});
