// axios.interceptors.js

import axios from "axios";

import { useMainStore } from "../stores/main.js";
import { useUserStore } from "../stores/user.js";

function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

axios.interceptors.response.use(
  // Do something with the response data
  (response) => {
    const { updateError } = useMainStore();
    updateError(null);
    return response;
  },
  (error) => {
    const { updateError } = useMainStore();
    const { setMustLogin, setMaintenance } = useUserStore();
    // Check if the error is due to the API being offline
    if (!error.response) {
      // Handle the error (e.g., show a message to the user)
      console.error("API is offline. Please try again later.");
      // Optionally, you can return a custom error message or object
      // return Promise.reject(
      //   new Error("API is offline. Please try again later.")
      // );

      setMaintenance(true);
      return false;
    }

    if (error?.response?.status === 503) {
      setMaintenance(true);
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      const errUrl = error.config.url;
      console.log(`Login Error ${error.response.status}:`, error.response);
      updateError(error);
      setMustLogin(!endsWith(errUrl, "/login"));
      // return Promise.resolve(error.response);
      return Promise.reject(error);
    }

    updateError(error);
    console.error("Error response:", error?.response);
    return Promise.reject(error);
  }
);
