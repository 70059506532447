export default {
    install: (app, options) => {
        app.config.globalProperties.$R = (country, rule) => {

            if (!country) return ''

            let key = country.toLowerCase() + '.' + rule

            let val = key.split('.').reduce((o, i) => {
                if (o) return o[i]
            }, options)


            if (val) return val

            key = (options?.default ?? '') + '.' + rule


            val = key.split('.').reduce((o, i) => {
                if (o) return o[i]
            }, options)

            if (val) return val

            return ''

        }
    }
}