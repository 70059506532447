import { ref, onMounted, onUnmounted, computed } from 'vue'

function useMediaQuery() {
    const sm = ref(false)
    const md = ref(false)
    const lg = ref(false)
    const xl = ref(false)

    getQueryFn("screen and (max-width: 767px) and (min-width: 100px)", 'sm', sm)()
    getQueryFn("screen and (max-width: 960px)  and (min-width: 768px)", 'md', md)()
    getQueryFn("screen and (max-width: 1199px)  and (min-width: 961px)", 'lg', lg)()
    getQueryFn("screen and (min-width: 1200px)", 'xl', xl)()
    return { sm, md, lg, xl }
}

function getQueryFn(query, className, val) {

    function fn(x) {
        val.value = x.matches
    }

    const x = window.matchMedia(query)

    onMounted(() => x.addEventListener("change", fn))
    onUnmounted(() => x.removeEventListener("change", fn))

    return () => {
        fn(x)
    }

}


export {
    useMediaQuery,
}