//create pinia store called 'main'

import { defineStore } from 'pinia'
import { ref } from 'vue';


export const useMainStore = defineStore('main', () => {

    const error = ref(null)

    function updateError(error) {
        this.error = error;
    }

    return { error, updateError }

});




