<template>
    <Sidebar :show-close-icon="false" :dismissable="false" :modal="false" v-model:visible="visibleLeft">
        <slot></slot>
    </Sidebar>
</template>

<script setup>
import { ref, watch } from "vue";
import Sidebar from "primevue/sidebar";

const props = defineProps(['visible','dismissable'])


watch(() => props.visible, (val) => {
    visibleLeft.value = val;
})

const visibleLeft = ref(props.visible);

</script>

<style lang="scss" scoped>

</style>