import axios from 'axios';
import api_url from './api_url'

export default class MiscService {

    //define a map variable
    versionMap = new Map();


    async getSoftwareList(token) {


        const response = await axios.get(`${api_url}/v1/software`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;

    }

    async getAllSoftwareList(token, country_id) {


        const response = await axios.get(`${api_url}/v1/allsoftware?c=${country_id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;

    }


    async getCampaignList(token) {
        const response = await axios.get(`${api_url}/v1/campaign`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }



    async getHardwareList(token, software_id, software_version, country_id, pos = -1) {


        let url = `${api_url}/v1/hardware?s=${software_id}&v=${software_version}&c=${country_id}`

        if (pos > -1) {
            url += `&pos=${pos}`
        }



        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }



    async getSoftwareVersionList(token, software_id, country_id, type) {
        ///v1/software/version?cloud=true&c=PT&s=3


        let url = `${api_url}/v1/software/version?s=${software_id}&t=${type}&c=${country_id}`

        if (this.versionMap.has(url)) {
            return this.versionMap.get(url);
        }

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.versionMap.set(url, response.data);

        return response.data;
    }

    async getSoftwarePrice(token, software_id, software_version) {
        ///v1/software/version?cloud=true&c=PT&s=3
        let url = `${api_url}/v1/software/price?s=${software_id}&v=${software_version}&sc=1`

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }

    async getModulesList(token, software_id) {
        let url = `${api_url}/v1/module?s=${software_id}`
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }

    async getCountriesList(token) {
        let url = `${api_url}/v1/country`
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }

    async getFidelizationList(token) {
        let url = `${api_url}/v1/license/fidelization/0`
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }


    async getLicenseHistory(token, license_id) {
        let url = `${api_url}/v1/license/history/${license_id}`
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;

    }



}