


const validatorFn = (t) => ({
    required(value, message) {
        return value ? "" : message ?? t("validation.required");
    },
    min(value, min, message) {
        return value.length >= min ? "" : message ?? t("validation.min", [min]);
    },
    max(value, max, message) {
        return value.length <= max ? "" : message ?? t("validation.max", [max]);
    },
    len(value, len, message) {
        return value.length == len ? "" : message ?? t("validation.len", [len]);
    },
    start(value, start, message) {
        return value.startsWith(start) ? "" : message ?? t("validation.start", [start]);
    },
    mask(inputValue, mask, message) {
        return this.maskeval(inputValue, mask, true, true, message) ? "" : message ?? t("validation.mask", [mask]);
    },
    masks(inputValue, mask, message) {
        return this.maskeval(inputValue, mask, true, false, message) ? "" : message ?? t("validation.masks", [mask]);
    },
    maske(inputValue, mask, message) {
        return this.maskeval(inputValue, mask, false, true, message) ? "" : message ?? t("validation.maske", [mask]);
    },
    maskeval(inputValue, mask, start, end, message) {
        var escapedMask = mask.replace(/[ .*+?^${}()|[\]\\]/g, '\\$&');
        var regexStr = escapedMask.replace(/#/g, '\\d').replace(/\\\*/g, '[\\d\\w]');
        // var regexStr = escapedMask.replace(/#/g, '\\d').replace(/%/g, '\\w');
        if (mask.includes('A')) {
            regexStr = regexStr.replace(/A/g, '[a-zA-Z]');
        }
        if (start) regexStr = '^' + regexStr
        if (end) regexStr = regexStr + '$'
        // var regex = new RegExp('^' + regexStr);
        // var regex = new RegExp(regexStr + '$');
        // var regex = new RegExp('^' + regexStr + '$');
        var regex = new RegExp(regexStr);
        let res = regex.test(inputValue)
        return res;
        // if (res) return ""

        // return message ?? t("validation.mask", [mask]);
    },
    chars(value, chars, message) {
        const regex = new RegExp(`[${chars}]`);
        const valueTest = regex.test(value);
        return valueTest ? "" : message ?? t("validation.chars", [chars]);
    },
    neq(value, neq, message) {
        const msg = message || t("validation.neq", [neq]);
        return value != neq ? "" : msg;
    },
    email(value, message) {
        //validate email regex
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valueTest = regex.test(value);
        return valueTest ? "" : message ?? t("validation.email");
    },
    num(value, message) {
        const regex = /^\d+$/;
        const valueTest = regex.test(value);
        return valueTest ? "" : message ?? t("validation.num");
    },
    nif(value, message) {
        return !validateNIF(value) ? message ?? t('validation.nif') : "";
    }
})

function validateNIF(value) {
    let error = 0;

    let comparador = 0;

    if (
        value.substr(0, 1) != '1' && // pessoa singular
        value.substr(0, 1) != '2' && // pessoa singular
        value.substr(0, 1) != '3' && // pessoa singular
        value.substr(0, 2) != '45' && // pessoa singular não residente
        value.substr(0, 1) != '5' && // pessoa colectiva
        value.substr(0, 1) != '6' && // administração pública
        value.substr(0, 2) != '70' && // herança indivisa
        value.substr(0, 2) != '74' && // herança indivisa
        value.substr(0, 2) != '75' && // herança indivisa
        value.substr(0, 2) != '71' && // pessoa colectiva não residente
        value.substr(0, 2) != '72' && // fundos de investimento
        value.substr(0, 2) != '77' && // atribuição oficiosa
        value.substr(0, 2) != '79' && // regime excepcional
        value.substr(0, 1) != '8' && // empresário em nome individual (extinto)
        value.substr(0, 2) != '90' && // condominios e sociedades irregulares
        value.substr(0, 2) != '91' && // condominios e sociedades irregulares
        value.substr(0, 2) != '98' && // não residentes
        value.substr(0, 2) != '99' // sociedades civis

    ) {
        console.log("check initial");
        error = 1;
    }
    var check1 = value.substr(0, 1) * 9;
    var check2 = value.substr(1, 1) * 8;
    var check3 = value.substr(2, 1) * 7;
    var check4 = value.substr(3, 1) * 6;
    var check5 = value.substr(4, 1) * 5;
    var check6 = value.substr(5, 1) * 4;
    var check7 = value.substr(6, 1) * 3;
    var check8 = value.substr(7, 1) * 2;

    var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
    var divisao = total / 11;
    var modulo11 = total - parseInt(divisao) * 11;
    if (modulo11 == 1 || modulo11 == 0) { comparador = 0; } // excepção
    else { comparador = 11 - modulo11; }


    var ultimoDigito = value.substr(8, 1) * 1;
    if (ultimoDigito != comparador) {
        console.log("check final");
        error = 1;
    }

    return value.length == 9 && error == 0;
    // ? message ?? t('validation.nif') : "";

}


export function useValidator(translator) {


    const validator = validatorFn(translator)

    const validate = (value, rules) => {
        const funcs = rules.split("|").map(f => f.trim())

        if (!funcs.includes("required") && !value) return ""

        for (let i = 0; i < funcs.length; i++) {

            const args = funcs[i].split(":")
            const func = args.shift()
            const rule = validator[func]
            if (!rule) return `No rule found '${func}'`

            const error = rule.call(validator, value, ...args)
            if (error != "") return error

        }

        return ""
    }

    const getValidation = function (validator, data) {
        return (field, rules = "") => {

            const funcs = rules.split("|")
            for (let i = 0; i < funcs.length; i++) {

                const args = funcs[i].split(":")
                const func = args.shift()
                const rule = validator[func.trim()]
                if (!rule) return "No rule found ${func}"
                const error = rule.call(validator, data[field], ...args)
                if (error != "") return error

            }

            return ""




            // const rule = rules[field]
            // if (!rule) return ""
            // const error = rule.call(rules, data[field])
            // return error != "" ? error : error_message;
        }
    }

    return {
        getValidation,
        validate,
    }
}
