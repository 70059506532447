<template>
  <div v-if="!hidden" class="tag-panel">
    <div @click="toggle" class="title">
      {{ $t("tag.tag_list") }}
      <i v-if="collapsed" class="pi pi-chevron-down"></i
      ><i v-if="!collapsed" class="pi pi-chevron-up"></i>
    </div>
    <div v-if="!collapsed" class="tag-list">
      <div v-for="tag in tags" class="tag">
        <div class="tag-selector">
          <div class="tag-name">{{ tag.name }}</div>
        </div>
        <div class="tag-options">
          <button @click="deleteTag(tag)" v-tooltip="$t('tag.remove_tag')">
            <i class="pi pi-trash"></i>
          </button>
          <button @click="editTag(tag)" v-tooltip="$t('tag.edit_tag')">
            <i class="pi pi-pencil"></i>
          </button>
        </div>
      </div>
      <button
        class="new-tag"
        v-tooltip="$t('tag.new_tag')"
        @click="createNewTag"
      >
        <i class="pi pi-plus"></i>
      </button>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :header="$t('tag.new_tag')"
    :style="{ width: '25rem' }"
  >
    <div class="formgrid grid">
      <div class="field col-12">
        <div class="flex gap-2 flex-column">
          <TextInput
            rules="required"
            :label="$t('tag.tag_name')"
            v-model="tag.name"
          ></TextInput>
        </div>
      </div>
      <div class="grid">
        <div class="field-col-12 ml-3">
          <h5>{{ t("tag.colors") }}:</h5>
          <div class="tag-color-list">
            <CustomTag
              static
              @click="selectColor(color)"
              v-for="color in [
                'red',
                'blue',
                'green',
                'yellow',
                'yellowgreen',
                'orange',
                'purple',
                'pink',
                'teal',
                'gray',
                'brown',
                'indigo',
              ]"
              :selected="selectedColor"
              :color="color"
            />
          </div>
        </div>
      </div>
      <!-- <pre>{{ tag }}</pre> -->
      <div class="field col-12 mt-4">
        <div class="flex gap-2 justify-content-end">
          <Button
            class="cancel"
            @click="visible = false"
            :label="t('general.cancel')"
          />
          <Button @click="doCreateNewTag" :label="t('general.save')" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from "primevue/dialog";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import CustomTag from "@/components/CustomTag.vue";

const visible = ref(false);

const props = defineProps({
  hidden: Boolean,
});

const confirm = useConfirm();
const { t, locale } = useI18n();

const newTag = {
  name: "",
  desc: "",
  props: "red",
};
import { useTagStore } from "@/stores/tag";
const tagStore = useTagStore();

const { tags } = storeToRefs(tagStore);

const tag = ref({ ...newTag });

const collapsed = ref(false);

const selectedColor = ref("red");

const selectColor = (color) => {
  if (selectedColor.value === color) {
    selectedColor.value = "";
    tag.value.props = "";
    return;
  }
  selectedColor.value = color;
  tag.value.props = color;
};

const showConfirm = (title, message, acceptFn, rejectFn) => {
  confirm.require({
    message: message,
    header: title,
    group: "normal",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "flex justify-center",
    acceptLabel: t("general.yes"),
    rejectLabel: t("general.no"),
    accept: () => {
      if (acceptFn) acceptFn();
    },
    reject: () => {
      if (rejectFn) rejectFn();
    },
  });
};

const toggle = () => {
  collapsed.value = !collapsed.value;
};

const createNewTag = () => {
  visible.value = true;
};

const doCreateNewTag = async () => {
  if ((tag?.value?.name ?? "").trim() === "") {
    return;
  }
  if (tag.value.id > 0) {
    await tagStore.updateTag(tag.value);
  } else {
    await tagStore.createTag(tag.value);
  }
  await refreshTags();
  tag.value = { ...newTag };
  visible.value = false;
};

const editTag = (ntag) => {
  tag.value = { ...ntag };
  visible.value = true;
  selectedColor.value = ntag.props;
};

const deleteTag = async (tag) => {
  const lics = await tagStore.getAllLicensesForTag(tag.id);
  if (lics && lics?.length > 0) {
    showConfirm(
      t("tag.remove_tag") + "-" + tag.name,
      t("tag.tag_remove_confirm"),
      () => doDeleteTag(tag)
    );
  } else if (lics) {
    doDeleteTag(tag);
  }
};

const doDeleteTag = async (tag) => {
  await tagStore.deleteTag(tag.id);
  await refreshTags();
};

const refreshTags = async () => {
  await tagStore.fetchTags();
};

onMounted(() => {
  refreshTags();
});
</script>

<style lang="scss" scoped>
.tag-panel {
  padding: 2rem;
  .title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    padding-bottom: 0.2em;
    border-bottom: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tag-list {
    .tag {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      user-select: none;
      padding: 0.1em 0.3em;
      border-radius: 5px;
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.3);
      }
      .tag-options {
        button {
          border: none;
          background: transparent;
          color: var(--primary-color);
          scale: 0.8;
          cursor: pointer;
          &:hover {
            color: var(--primary-color-dark);
            scale: 1;
          }
        }
      }
    }
  }
  color: white;

  button.new-tag {
    background-color: var(--primary-color);
    border-radius: 5px;
    border: none;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
    padding: 0.3rem;
    cursor: pointer;
    &:hover {
      background-color: var(--background-color-2);
    }
  }
}

.tag-color-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
