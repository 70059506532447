
import axios from "axios";


export default class CombosService {

    constructor(base_url) {
        if (base_url) {
            this.base_url = base_url;
        } else {
            // this.base_url = 'http://192.168.10.112:3377';
            this.base_url = 'http://localhost:3377';
        }
    }


    async getCampainList(token) {
        // const response = await axios.get(`${this.base_url}/campanhas`, {
        const response = await axios.get(`${this.base_url}/licenses/newlicense`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }

    async getCampainDetail(token, campainId, software = 0, version = 0, countryId = 'PT') {
        const response = await axios.get(`${this.base_url}/licenses/campanha/${campainId}?software=${software}&versao=${version}&pais=${countryId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }



    // getSoftwareList(token) 
    async getSofwareList(token) {
        // const response = await axios.get(`${this.base_url}/softwares`, {
        const response = await axios.get(`${this.base_url}/softwares`, {

            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }


    // getHardwareList by troken , softwareId  and countryId
    async getHardwareList(token, softwareId, countryId = 'PT') {

        // const response = await axios.get(`${this.base_url}/hardwares`, {
        const response = await axios.get(`${this.base_url}/hardwareprecos?software=${softwareId}&pais=${countryId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    }



}