import { defineStore, storeToRefs } from "pinia"
import { computed, ref } from "vue"


import MiscService from "../services/misc"
import { useUserStore } from "./user"
import { useI18n } from "vue-i18n";


export const useMiscStore = defineStore('misc', () => {
    const { t } = useI18n();

    const us = useUserStore()
    const { token } = storeToRefs(us)


    const softwareList = ref([])
    const allSoftwareList = ref([])
    const campaignList = ref([])
    const hardwareList = ref([])
    const softwareVersionList = ref([])
    const modulesList = ref([])
    const softwarePrice = ref(0)
    const fidelzationPrice = ref(0)
    const countryList = ref([])
    const fidelizationList = ref([])
    const licenseHistory = ref([])


    const ms = new MiscService()


    // ZSLW-64 - Software version name
    const softwareVersionName = computed(() => {
        return (software_id, type) => {
            const soft = allSoftwareList.value.find(e => e.codigo == software_id)
            if (soft) {
                const version = soft.versions.find(v => v.id == type)
                if (version) {
                    return version.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        }
    })

    async function getSoftwareList() {
        if (softwareList.value.length > 0) {
            return;
        }
        try {
            const data = await ms.getSoftwareList(token.value)

            softwareList.value = [{ descricao: 'Escolha o software', codigo: -1 }, ...data.data]
        } catch (error) {
            softwareList.value = []
        }
    }

    async function getAllSoftwareList(country_id = 'PT') {
        if (softwareList.value.length > 0) {
            return;
        }
        try {
            const data = await ms.getAllSoftwareList(token.value, country_id)

            allSoftwareList.value = data.data
        } catch (error) {
            softwareList.value = []
        }
    }

    async function getCampaignList() {
        if (campaignList.value.length > 0) {
            return;
        }
        try {
            const data = await ms.getCampaignList(token.value)
            const noneStr = t('general.none_female')
            campaignList.value = [{ descricao: noneStr, id: -1 }, ...data.data]
            campaignList.value = campaignList.value.map((item) => {
                return {
                    ...item,
                    descricao: item.descricao.replace('?', '€')
                }
            }
            )
        } catch (error) {
            console.log("getCampaignList -> error", error)
            campaignList.value = []
        }
    }


    async function getHardwareList(software_id, software_version, country_id, pos = -1) {
        try {
            const data = await ms.getHardwareList(token.value, software_id, software_version, country_id, pos)
            let list = null
            if (Array.isArray(data.data) && data.data.length > 0) {
                list = data.data.map((item) => {
                    return {
                        ...item,
                        descricao: item.descricao + " - " + parseFloat(item.valor).toFixed(2) + "€"
                    }
                })
            }else list = data.data

            if (pos > -1) {
                hardwareList.value = [list]
            } else {
                hardwareList.value = [{ descricao: t('general.none_male'), hardware: -1 }, ...list]
            }
            return hardwareList.value
        } catch (error) {
            console.log("getHardwareList -> error", error)
            hardwareList.value = []
        }


    }

    async function getSoftwareVersionList(software_id, type, country_id = 'PT') {
        try {
            const data = await ms.getSoftwareVersionList(token.value, software_id, country_id, type)
            softwareVersionList.value = (data?.data || []).map((item) => {
                return {
                    ...item,
                    name: item.name
                }
            })

            return softwareVersionList.value

        } catch (error) {
            console.log("getSoftwareVersionList -> error", error)
            softwareVersionList.value = []
        }
    }


    async function getSoftwarePrice(software_id, software_version) {
        try {
            const data = await ms.getSoftwarePrice(token.value, software_id, software_version)
            softwarePrice.value = data.data
            return data.data
        } catch (error) {
            console.log("getSoftwarePrice -> error", error)
            softwarePrice.value = 0
        }
    }

    async function getFidelizationPrice(software_id, software_version, country_id) {
        try {
            const data = await ms.getFidelizationPrice(token.value, software_id, software_version, country_id)
            fidelzationPrice.value = data.data.price
        } catch (error) {
            console.log("getFidelizationPrice -> error", error)
            fidelzationPrice.value = 0
        }
    }

    async function getModulesList(software_id) {
        try {
            const data = await ms.getModulesList(token.value, software_id)
            modulesList.value = data.data

        } catch (error) {
            console.log("getModulesList -> error", error)
            modulesList.value = []
        }
    }

    async function getCountriesList() {
        try {
            const data = await ms.getCountriesList(token.value)
            countryList.value = data.data

        } catch (error) {
            console.log("getCountriesList -> error", error)
            countryList.value = []
        }
    }

    async function getFidelizationList() {
        try {
            const data = await ms.getFidelizationList(token.value)

            let list = data?.data ?? []




            fidelizationList.value = list.map(e => {
                if (e.id == 1) return { ...e, name: 'Freebee Base' }
                if (e.id == 2) return { ...e, name: 'Freebee Plus' }
                if (e.id == 3) return { ...e, name: 'Freebee Plus+' }
            }) || [];

            if (fidelizationList.value.length > 0) {
                fidelizationList.value = [{ name: t('general.none_female'), id: -1 }, ...fidelizationList.value]
            }


        } catch (error) {
            console.log("getFidelizationList -> error", error)
            fidelizationList.value = []
        }
    }

    const cloudSoftware = computed(() => softwareList.value?.filter(e => e.type === 'cloud') ?? [])








    async function getLicenseHistory(license_id) {
        try {

            const data = await ms.getLicenseHistory(token.value, license_id)
            licenseHistory.value = data.data
            return data.data

        } catch (error) {
            console.log("getLicenseHistory -> error", error)
            licenseHistory.value = []
        }
    }







    return {
        softwareList, getSoftwareList, campaignList, getCampaignList, hardwareList, getHardwareList, softwareVersionList, getSoftwareVersionList, softwarePrice, getSoftwarePrice, fidelzationPrice, getFidelizationPrice, modulesList, getModulesList, cloudSoftware,
        getCountriesList, countryList, getFidelizationList, fidelizationList, getLicenseHistory, licenseHistory, getAllSoftwareList, allSoftwareList, softwareVersionName
    }


})