<template>
  <div class="app-panel">
    <Button v-if="backButton" @click="$emit('back')" class="back-button p-button-text gap-3">
      <img src="@/assets/back_arrow.svg" alt="back" />
      {{ backButtonLabel }}</Button>
    <div v-if="title.length > 0" class="title">{{ title }}</div>
    <slot></slot>
    <ScrollTop target="parent" :threshold="100" />
  </div>
</template>

<script setup>
import { useToast } from "primevue/usetoast";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import ScrollTop from "primevue/scrolltop";

defineEmits(["back"]);
const toast = useToast();

const { t } = useI18n();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  backButton: {
    type: Boolean,
    default: false,
  },
  backButtonLabel: {
    type: String,
    default: "voltar",
  },
  error: {
    type: Object,
    default: {},
  },
});

watch(
  () => props.error,
  (val) => {
    if (val) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: t(val?.i18n ?? "") ?? "Error",
        life: 6000,
      });
    }
  }
);
</script>

<style lang="scss" scoped>
.app-panel {
  background-color: #fff;
  padding: 1vh 1.5vw;
  max-height: calc(100vh - 85px);
  overflow: auto;

  .title {
    font-size: 36px;
    font-weight: 500;
    color: #e84e0f;
    margin-bottom: 10px;
  }

  .back-button {
    font-size: 23px;
    color: black;
  }
}
</style>
