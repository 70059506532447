<template>
    <span :class="tagClass" class="tag">{{ tag }}
        <div @click="emit('delete')" class="delete-button"><i style="font-size: 60%;" class="pi pi-times"></i></div>
    </span>
</template>

<script setup>


import { computed } from 'vue'

const emit = defineEmits(['delete'])


const props = defineProps({
    tag: String,
    color: String,
    selected: String,
    static: Boolean
})

const tagClass = computed(() => {
    return {
        selected: props.selected == props.color,
        [props.color]: true,
        static: props.static
    }
})

// const selected = computed(() => true))

// const tagClass = computed(() => {
//     return {
//         selected: selected.value,
//         `${props.color}`: true
//     }
// })


</script>

<style lang="scss" scoped>
.tag {
    padding: 0.2em 0.4em;
    border-radius: 15%;

    &.static {
        min-width: 40px;
        min-height: 30px;
    }

    cursor: pointer;
    
    &:hover,
    &.selected {
        position: relative;
        scale: 1.2;
        border: 2px solid transparent;
    }

    &.selected {
        border: 2px solid #333;
    }

    .delete-button {
        color: white;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px 2px 2px 4px;
        border-radius: 50%;
        background-color: red;
        border: 1px solid red;
        display: none;
    }

    &:hover:not(.static) {
        .delete-button {
            display: flex;
        }
    }

}

// get 10 colors for tag
.tag.red {
    background-color: red;
    color: white;
}

.tag.blue {
    background-color: #007bff;
    /* Blue */
    color: white;
}

.tag.green {
    background-color: #28a745;
    /* Green */
    color: white;
}

.tag.yellow {
    background-color: #ffc107;
    /* Yellow */
    color: black;
}

.tag.orange {
    background-color: #fd7e14;
    /* Orange */
    color: white;
}

.tag.purple {
    background-color: #6f42c1;
    /* Purple */
    color: white;
}

.tag.pink {
    background-color: #e83e8c;
    /* Pink */
    color: white;
}

.tag.teal {
    background-color: #20c997;
    /* Teal */
    color: white;
}

.tag.gray {
    background-color: #6c757d;
    /* Gray */
    color: white;
}

.tag.brown {
    background-color: #85583f;
    /* Brown */
    color: white;
}

.tag.indigo {
    background-color: #46088a;
    /* Indigo */
    color: white;
}

.tag.yellowgreen {
    background-color: #9acd32;
    /* Yellow Green */
    color: #333;
}
</style>