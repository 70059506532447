<template>
    <div class="maintenance">
        <div class="logo">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="332" height="100"
                viewBox="0 0 166.19 49.829">
                <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#ff3301" />
                        <stop offset="1" stop-color="#ff6601" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="0.852" y1="0.177" x2="0.132" y2="0.805"
                        gradientUnits="objectBoundingBox">
                        <stop offset="0.25" stop-color="#ff3301" />
                        <stop offset="0.59" stop-color="#ff4701" />
                        <stop offset="1" stop-color="#ff6601" />
                    </linearGradient>
                </defs>
                <g id="Artwork_4" data-name="Artwork 4" transform="translate(83.095 24.915)">
                    <g id="Grupo_3521" data-name="Grupo 3521" transform="translate(-83.095 -24.915)">
                        <g id="Grupo_3519" data-name="Grupo 3519" transform="translate(61.548 16.804)">
                            <path id="Caminho_5625" data-name="Caminho 5625"
                                d="M280.9,35.611a1.927,1.927,0,1,1-1.922-1.914,1.922,1.922,0,0,1,1.922,1.914m-3.657,0a1.735,1.735,0,0,0,3.469,0,1.735,1.735,0,0,0-3.469,0m1.931-1.026a.573.573,0,0,1,.581.59.56.56,0,0,1-.436.547l.53.914h-.223l-.521-.9H278.5v.9h-.2V34.585Zm-.675.974h.675a.39.39,0,0,0,.394-.384.4.4,0,0,0-.394-.41H278.5Z"
                                transform="translate(-176.258 -30.756)" fill="#fff" />
                            <path id="Caminho_5626" data-name="Caminho 5626"
                                d="M178.917,35.214a5.777,5.777,0,0,1,5.708,5.941,5.418,5.418,0,0,1-.047.693H174.3a4.886,4.886,0,0,0,5.289,4.786,10.457,10.457,0,0,0,3.953-.9l.37.832a10.636,10.636,0,0,1-4.323,1.041c-3.838,0-6.335-2.46-6.335-6.2,0-3.352,2.176-6.2,5.666-6.2m4.663,5.756c-.166-2.344-1.773-4.786-4.663-4.786-2.658,0-4.481,2.1-4.621,4.786Z"
                                transform="translate(-133.282 -31.384)" fill="#fff" />
                            <path id="Caminho_5627" data-name="Caminho 5627"
                                d="M114.911,35.528v.879l-8.369,10.2.023.046,4.022-.023h4.323v.925h-9.872v-.855L113.5,36.476l-.023-.046-4.022.023h-4.416v-.925Z"
                                transform="translate(-105.039 -31.514)" fill="#fff" />
                            <path id="Caminho_5628" data-name="Caminho 5628"
                                d="M130.975,35.213a6.2,6.2,0,1,1-6.289,6.2,6.082,6.082,0,0,1,6.289-6.2m-5.133,6.2a5.133,5.133,0,1,0,5.11-5.224,5.025,5.025,0,0,0-5.11,5.224"
                                transform="translate(-113.174 -31.384)" fill="#fff" />
                            <path id="Caminho_5629" data-name="Caminho 5629"
                                d="M152.386,36.184v1.447a4.377,4.377,0,0,1,4.3-2.418c2.9,0,4.46,1.918,4.46,5.179V47.42h-1.109V40.345c0-2.589-1.2-4.161-3.51-4.161-2.875,0-4.143,2.566-4.143,4.578V47.42h-1.11V36.184Z"
                                transform="translate(-124.183 -31.384)" fill="#fff" />
                            <path id="Caminho_5630" data-name="Caminho 5630"
                                d="M230.746,35.213a6.2,6.2,0,1,1-6.288,6.2,6.083,6.083,0,0,1,6.288-6.2m-5.132,6.2a5.133,5.133,0,1,0,5.109-5.224,5.025,5.025,0,0,0-5.109,5.224"
                                transform="translate(-154.484 -31.384)" fill="#fff" />
                            <path id="Caminho_5631" data-name="Caminho 5631"
                                d="M250.193,34.366h-1.471v-.888h1.471V33c0-3.851,2.521-4.318,3.688-4.318a3.416,3.416,0,0,1,.608.047v.911a2.728,2.728,0,0,0-.537-.047c-.935,0-2.778.373-2.778,3.384v.505h3.315v.888h-3.315V44.9h-.98Z"
                                transform="translate(-164.531 -28.678)" fill="#fff" />
                            <path id="Caminho_5632" data-name="Caminho 5632"
                                d="M262.929,35.058h-1.567v-.9h1.567V30.312h1v3.843h3.11v.9h-3.11v6.055c0,3.134,1.876,3.537,2.825,3.537a2.769,2.769,0,0,0,.546-.047v.926a3.391,3.391,0,0,1-.594.047c-1.234,0-3.774-.451-3.774-4.392Z"
                                transform="translate(-169.764 -29.355)" fill="#fff" />
                            <path id="Caminho_5633" data-name="Caminho 5633"
                                d="M207.9,45.146a5.15,5.15,0,0,0,3.666,1.489c1.443,0,2.719-.756,2.719-2.222,0-3-6.5-2.152-6.5-6.007,0-2.1,1.773-3.193,3.784-3.193a4.665,4.665,0,0,1,3.5,1.277l-.591.8a4.046,4.046,0,0,0-2.98-1.112c-1.348,0-2.672.615-2.672,2.152,0,3.074,6.5,2.152,6.5,6.078,0,1.892-1.608,3.192-3.784,3.192a5.72,5.72,0,0,1-4.28-1.7Z"
                                transform="translate(-147.366 -31.384)" fill="#fff" />
                        </g>
                        <g id="Grupo_3520" data-name="Grupo 3520">
                            <path id="Caminho_5634" data-name="Caminho 5634"
                                d="M.657,57.925,7.95,73.813,50.1,60.515,49.843,59.3Z" transform="translate(-0.272 -23.984)"
                                fill="url(#linear-gradient)" />
                            <path id="Caminho_5635" data-name="Caminho 5635"
                                d="M40.1,0,.657,33.941,7.95,49.829,48.29,14.293Z" transform="translate(-0.272)"
                                fill="url(#linear-gradient-2)" />
                            <path id="Caminho_5636" data-name="Caminho 5636" d="M0,11.831l.506.721,47.512,1.742L39.83,0Z"
                                fill="url(#linear-gradient)" />
                        </g>
                    </g>
                </g>
            </svg>

        </div>
        <h1>Em Manutenção</h1>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.maintenance {

    width: 100vw;
    height: 100vh;
    background: #f6f6f6;
    background: linear-gradient(45deg, #1e1e2d, #e84e0f);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    color: white;
}


h1 {
    font-size: 400%;
    text-shadow: 2px 2px 2px black;
}
</style>