<template>
    <Sidebar :show-close-icon="false" position="right" :dismissable="false" :modal="false" v-model:visible="barVisible">
        <div class="right-bar">
            <slot></slot>
        </div>
    </Sidebar>
</template>

<script setup>
import { ref, watch } from "vue";
import Sidebar from "primevue/sidebar";

const props = defineProps(['visible'])


watch(() => props.visible, (val) => {
    barVisible.value = val;
})

const barVisible = ref(props.visible);

</script>

<style lang="scss" scoped>

    .right-bar {
        background-color: lightcoral;
        height: 100vh;
        overflow: auto;
    }

</style>